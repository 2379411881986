.size {
  font-size: 12px;
}

.businessconsulting:hover {
  transform: scale(1.1);
}

.digupulse:hover {
  transform: scale(1.1);
}

.solfut:hover {
  transform: scale(1.1);
}

.wonderlives:hover {
  transform: scale(1.1);
}

.nascidosparatriunfar:hover {
  transform: scale(1.1);
}

.footer {
  color: white;
  margin: 0px;
  padding: 10px;
  max-width: 100%;
  min-width: 100%;
  width: 100%;
  margin-top: 80px;
}

.footerFirstSectionContainer {
  background-color: #004579;
  padding: 0px;
  margin: 0px;
}

.footerFirstSection {
  padding: 0px;
  margin: 0px;
  display: flex;
  justify-content: center;
}

.footerSecondSectionContainer {
  background-color: white;
  margin: 0px;
  padding: 0px;
}

.footerMainContainer {
  margin: 25px 0px 0px 0px;
  padding: 0px 0px 25px 0px;
}

.footerCopyright {
  color: #004579;
}

.footerCopyrightText {
  font-size: 10px;
  font-family: "Rubik", Sans-serif;
  font-weight: normal;
}

.footerMenuItem {
  font-family: "Rubik", Sans-serif;
  font-size: 14px;
  font-weight: 700;
  margin: 0px;
  padding-left: 15px;
  text-transform: uppercase;
  line-height: 1.7em;
}

.footerMenuItemChildren a {
  font-size: 12px;
  font-weight: 300;
  text-transform: none;
  color: white;
  text-decoration: none;
  line-height: 1.6em;
}

.footerSocialMedia {
  margin: 0px;
  padding: 0px;
}

/* Small devices (landscape phones, 576px and up) */

@media (min-width: 576px) {
  .footerMainContainer {
    margin-top: 35px;
  }
}

/* Medium devices (tablets, 768px and up) */

@media (min-width: 768px) {
  .footerMainContainer {
    margin-top: 55px;
  }
  .footerMenuItem {
    padding-left: 0px;
  }
}

/* Large devices (desktops, 992px and up) */

@media (min-width: 992px) {
  .footerMainContainer {
    margin-top: 55px;
  }
}

/* Extra large devices (large desktops, 1200px and up) */

@media (min-width: 1200px) {
  .footerMainContainer {
    max-width: 1360px!important;
  }
  .footerMainContainer {
    margin-top: 95px;
  }
  .footerSocialMedia {
    margin-bottom: 15px;
  }
}