.mobile-cart {
  width: 90vw;
}

.row-image-height {
  height: 12vh;
}

.text-height {
  height: 6vh;
}

.center {
  position: relative;
}

.cartItemsContainer {
  margin-top: 10px;
}

.cartContainer {
  margin-top: 50px;

}

.cartGridContainer {
  display: grid;
  grid-template-columns: repeat(14, 7%);
  grid-template-rows: 1fr auto auto;
  margin-bottom: 15px;
}

.cartGridContainerCheckout {
  display: grid;
  grid-template-columns: repeat(14, 7%);
  grid-template-rows: 1fr auto auto;
  margin-bottom: 15px;
}

/*.center span {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: auto;
  transform: translate(-50%, -50%);
}*/

.cartTitleParent {
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: column;
}

.cartTitle {
  font-family: "Montserrat", sans-serif !important;
  font-weight: 600;
  font-style: normal;
  font-size: 1.5em;
  color: rgba(71, 73, 79, 1);
}

.cartCheckoutButton {
  font-size: 25px;
  background-color: #ca006a;
  border: none;
  width: 30%;
  min-width: 200px;
  text-wrap: balance;
  width: 100%;
  margin-top: 10px;
}

.cartCheckoutButtonContinueBuy {
  font-size: 15px;
  background-color: #034275;
  border: none;
  width: 30%;
  min-width: 200px;
  text-wrap: balance;
  width: 100%;
  margin-top: 10px;
}

.cartRemoveAllItems {
  background-color: grey;
  min-width: 100%;
  border: none;
}

.cartItemContainer {
  margin: 32px 0px 32px 0px;
}

.cartItemProductLink {
  font-size: 17px;
  display: inline-block;
  white-space: inherit;
  overflow: hidden;
  text-overflow: ellipsis;
  color: black;
  text-align: left;
}

.cartItemButton {
  background-color: #ca006a;
  min-width: 100%;
  border: none
}

.cartItemRemove {
  text-align: center;
  display: flex;
  align-content: center;
  justify-content: flex-end;
  align-items: center;
  margin: 0px;
  padding: 0px;
}

.cartItemTitleContainer {
  display: flex;
  align-content: center;
  align-items: center;
}

.cartItemActionsGroupContainer {
  display: flex;
  justify-content: center;
}

.cartItemPriceQuantityContainer {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px;
}

.cartItemPrice {
  font-size: 17px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  align-content: center;
}

.cartItemQuantityInput {
  text-align: center;
  width: 30%;
}

.cartItemUpdateQuantityContainer {
  margin-top: 7px;
  text-align: center;
  font-size: 14px;
}

.cartItemUpdateQuantityContainerLink {
  font-weight: 400;
}

.cartItemUpdateQuantityContainerLink:hover {
  cursor: pointer;
  font-weight: 500;
  font-size: 15px;
}

.cartBottomCheckoutParent {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0px;
  font-size: 20px;
  width: 100%;
  display: block;
  z-index: 50;
  height: 100%;
}

.cartBottomCheckoutContainer {
  background-color: white;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.cartTitleParent {
  grid-column-start: 1;
  grid-column-end: 15;
  grid-row-start: 1;
  grid-row-end: 1;
}

.cartTitle {
  text-align: center;
  font-size: 2em;
}

.cartItemsContainer {
  overflow: hidden;
  grid-row-start: 2;
  grid-row-end: 3;
  grid-column-start: 1;
  grid-column-end: 15;
}

.cartRelatedItems {
  grid-column-start: 1;
  grid-column-end: 15;
  grid-row-start: 3;
  grid-row-end: 4;
}

.cartPossibleActionsBeforeCheckout {
  grid-row-start: 4;
  grid-row-end: 5;
  grid-column-start: 1;
  grid-column-end: 15;
}

.cartPossibleActionsBeforeCheckoutContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  float: right;
}

.cartPossibleActionTrash {
  width: 60px;
}

.cartErrorMessage {
  grid-row-start: 2;
  grid-row-end: 5;
  grid-column-start: 1;
  grid-column-end: 15;
}

/* Until XS*/

@media only screen and (max-width: 575px) {
  .cartItemTitleContainer {
    margin-bottom: 20px;
  }

  .cartItemProductLink {
    text-align: center;
  }

  .cartItemActionContainer {
    margin-bottom: 10px;
  }

  .cartItemRemove {
    display: flex;
    justify-content: center;
    text-align: center;
  }
}

/* Until MD*/

@media only screen and (max-width: 720px) {
  .cartItemActionContainer {
    margin-bottom: 10px;
  }
}

/* Until LG*/

@media only screen and (max-width: 992px) {
  .cartItemTitleContainer {
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
  }

  .cartItemProductLink {
    text-align: center;
  }

  .cartItemActionContainer {
    margin-bottom: 10px;
  }

  .cartBottomCheckoutContainer {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-collapse: collapse;
  }
}

@media only screen and (min-width:993px) {
  .cartContainer {
    margin-top: 50px;
  }

  .cartGridContainer {
    display: grid;
    grid-template-columns: repeat(14, 7.12%);
    grid-template-rows: 1fr 70% 15%;
    max-height: calc(90vh - var(--navbar-height) - var(--checkout-button-height));
    min-height: calc(90vh - var(--navbar-height) - var(--checkout-button-height));
  }

  .cartGridContainerCheckout {
    display: grid;
    grid-template-columns: repeat(14, 7.12%);
    grid-template-rows: 1fr 80% 15%;
    max-height: calc(90vh - var(--navbar-height) - var(--checkout-finalizar-button-max-height));
    min-height: calc(90vh - var(--navbar-height) - var(--checkout-finalizar-button-min-height));

  }

  .cartItemsContainer {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 100%;
  }

  .cartTitleParent {
    grid-column-start: 1;
    /* Antes quando tinha o carrinho */
    /* grid-column-end: 11; */
    grid-column-end: 15;
    grid-row-start: 1;
    grid-row-end: 2;
    /* justify-content: flex-start; */
  }

  .cartTitle {
    text-align: left;
    font-size: 2em;
  }

  .cartItemsContainer {
    grid-row-start: 2;
    grid-row-end: 3;
    grid-column-start: 1;
    /* Antes quando tinha o carrinho */
    /* grid-column-end: 11; */
    grid-column-end: 15;
  }

  .cartRelatedItems {
    grid-column-start: 12;
    grid-column-end: 15;
    grid-row-start: 1;
    grid-row-end: 5;
  }

  .cartPossibleActionsBeforeCheckout {
    grid-row-start: 3;
    grid-row-end: 4;
    grid-column-start: 1;
    /* Antes quando tinha o carrinho */
    /* grid-column-end: 11; */
    grid-column-end: 15;
  }

  .cartErrorMessage {
    grid-row-start: 2;
    grid-row-end: 4;
    grid-column-start: 1;
    /* Antes quando tinha o carrinho */
    /* grid-column-end: 11; */
    grid-column-end: 15;
  }

  .cartPossibleActionTrash {
    width: 100px;
  }
}