@import "~bootstrap/dist/css/bootstrap.css";
@import "~cookieconsent/build/cookieconsent.min.css";

:root {
  --navbar-height: 80px;
  --checkout-button-height: 180px;
  --checkout-finalizar-button-min-height: 75vh;
  --checkout-finalizar-button-max-height: 45vh;
}

body {}

code {}

/* Controlar CSS do container principal */

.page-section {
  margin-top: 7px;
}