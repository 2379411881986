.relatedProductsContainerOrder {
    margin: 20px 0px 35px 0px;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.relatedProductsTitleParentOrder {
    font-family: "Montserrat", sans-serif!important;
    font-weight: 600;
    font-style: normal;
    font-size: 17px;
    color: rgba(71, 73, 79, 1);
    text-align: left;
}

.relatedProductsItemsContainerOrder {
    background-color: white;
    margin-top: 15px;
    overflow-x: auto;
    display: flex;
    padding-left: 0px;
    padding-right: 0px;
}

.relatedProductItemContainerOrder {
    flex: 1;
    display: flex;
    flex-direction: row;
    padding: 0px 8px 0px 8px;
    align-items: center;
    align-content: center;
    min-height: 100%;
    min-width: 45%;
}

.relatedProductItemSubContainerOrder {
    min-width: 100%;
    min-height: 100%;
}

.relatedProductItemContainerOrder .itemImgContainerOrder {
    --imgWidth: 100%;
    --imgHeight: 40vw;
    min-width: var(--imgWidth);
    width: var(--imgWidth);
    max-width: var(--imgWidth);
    min-height: var(--imgHeight);
    height: var(--imgHeight);
    max-height: var(--imgHeight);
    display: flex;
    align-items: center;
    justify-content: center;
}

.relatedProductItemContainerOrder .itemImgOrder {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.relatedProductItemContainerOrder .itemTitleOrder {
    font-family: "Rubik", Sans-serif;
    font-size: 0.85em;
    font-weight: 700;
    --lh: 21px;
    line-height: var(--lh);
    color: #004579;
    text-align: center;
    margin-top: 10px;
    display: flex;
    justify-content: center;
    --max-lines: 2;
}

.relatedProductItemContainerOrder .itemTitleOrder a {
    color: inherit;
    text-decoration: none;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    max-height: calc(var(--lh) * var(--max-lines));
    min-height: calc(var(--lh) * var(--max-lines));
}

.relatedProductItemContainerOrder .itemTitleOrder:hover {
    color: #0097DB;
}

.relatedProductItemContainerOrder .itemPriceOrder {
    margin-top: 10px;
    text-align: center;
    justify-content: center;
    font-family: "Rubik", Sans-serif;
    color: #004579;
    font-weight: normal;
    display: flex;
    align-items: center;
}

.relatedProductItemContainerOrder .priceToOtherUsersOrder {
    font-size: 0.85em;
    color: dimgray;
    margin-right: 3px;
}

.relatedProductItemContainerOrder .itemReadMoreOrder {
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    text-transform: capitalize;
    text-decoration: underline;
    fill: #0097DB;
    color: #0097DB;
    background-color: #0097DB00;
    padding: 0px 0px 0px 0px;
    cursor: pointer;
    font-family: "Rubik", Sans-serif;
    letter-spacing: 1px;
    transition: all .3s;
    margin-top: 10px;
    display: none;
}

.relatedProductItemContainerOrder .itemReadMoreOrder a {
    text-decoration: none;
    color: inherit;
}

.relatedProductItemContainerOrder .itemReadMoreOrder:hover {
    color: #3FBF55;
}

.relatedProductItemContainerOrder .itemButtonContainerOrder {
    display: flex;
    justify-content: center;
    min-width: 100%;
}

.relatedProductItemContainerOrder .itemButtonOrder {
    margin-top: 12px;
    padding: 13px 15px 13px 15px;
    background-color: #3FBF55;
    color: white;
    cursor: pointer;
    font-weight: bold;
    /* font-size: 16px; */
    font-size: 0.75em;
    font-family: "Rubik", Sans-serif;
    text-transform: uppercase;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 100%;
}

.relatedProductItemContainerOrder .itemButtonOrder:hover {
    background-color: #219935;
}

.relatedProductItemContainerOrder .itemButtonOrder .itemButtonIconOrder {
    font-size: 12px;
    margin-right: 10px;
}

/* Small devices (landscape phones, 576px and up) */

@media (min-width: 576px) {
    .relatedProductItemContainerOrder .itemTitleOrder {
        text-align: center;
    }
    .relatedProductItemContainerOrder .itemReadMoreOrder {
        text-align: center;
    }
    .relatedProductItemContainerOrder .itemPriceOrder {
        text-align: center;
        justify-content: center;
    }
    .relatedProductItemContainerOrder {
        display: flex;
        flex-direction: column;
        padding: 0px 5px 0px 5px;
        align-items: center;
        padding-bottom: 50px;
    }
    .relatedProductItemContainerOrder .itemImgContainerOrder {
        --imgWidth: 100%;
        --imgHeight: 20vw;
    }
}

/* Medium devices (tablets, 768px and up) */

@media (min-width: 768px) {
    .relatedProductItemContainerOrder .itemImgContainerOrder {
        --imgWidth: 100%;
        --imgHeight: 15vw;
    }
    .relatedProductItemContainerOrder {
        min-width: unset;
    }
}

/* Large devices (desktops, 992px and up) */

@media (min-width: 992px) {
    .relatedProductItemContainerOrder .itemImgContainerOrder {
        --imgWidth: 100%;
        --imgHeight: 15vw;
    }
    .relatedProductItemContainerOrder {
        padding-bottom: 50px;
    }
    .relatedProductItemContainerOrder .itemButtonOrder {
        font-size: 1em;
    }
}


/* Extra large devices (large desktops, 1200px and up) */
/*
@media (min-width: 1200px) {
    .relatedProductsContainerOrder {
        padding: 0px;
        min-width: 100%;
        max-height: 100%;
        margin: 0px;
        display: flex;
        flex-direction: column;
    }
    .relatedProductsItemsContainerOrder {
        flex-direction: column;
        max-height: 100%;
        overflow: auto;
    }
    .relatedProductItemContainerOrder .itemSecondCol {
        display: flex;
        flex-direction: column;
        align-content: flex-end;
        align-items: flex-end;
        justify-content: flex-start;
    }
    .relatedProductItemContainerOrder .itemTitleOrder {
        min-width: 100%;
        text-align: right;
        justify-content: flex-end;
    }
    .relatedProductItemContainerOrder .itemReadMoreOrder {
        min-width: 100%;
        text-align: right;
        justify-content: flex-end;
        display: flex;
    }
    .relatedProductItemContainerOrder .itemPriceOrder {
        text-align: right;
        justify-content: flex-end;
    }
    .relatedProductItemContainerOrder {
        display: flex;
        flex-direction: row;
        padding: 0px 15px 0px 15px;
        padding-bottom: 0px;
        flex: 1;
        max-height: auto;
        min-height: auto;
        height: auto;
        margin-bottom: 35px;
    }
    .relatedProductItemContainerOrder .itemImgContainerOrder {
        --imgWidth: 100%;
        --imgHeight: 6vw;
    }
    .relatedProductItemContainerOrder .itemButtonContainerOrder {
        min-width: 100%
    }
    .relatedProductItemContainerOrder .itemButtonOrder {
        padding: 8px 15px 8px 15px;
        font-size: 1em;
    }
    .relatedProductItemContainerOrder .relatedProductItemSubContainerOrder {
        min-width: 100%;
        min-height: auto;
    }
    .relatedProductItemContainerOrder .itemTitleOrder a {
        color: inherit;
        text-decoration: none;
        overflow: hidden;
        text-overflow: ellipsis;
        display: unset;
        -webkit-line-clamp: unset;
        -webkit-box-orient: vertical;
        max-height: unset;
        min-height: unset;
    }
}
*/